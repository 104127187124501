import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function list(params) {
  return request({
    url: '/api/prim_prod/prim_product/',
    method: 'get',
    params
  })
}

export function add(data) {
  return request({
    url: '/api/prim_prod/prim_product/',
    method: 'post',
    data
  })
}

export function createProto(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/productPrototype/create',
    method: 'post',
    data
  })
}

export function updateProto(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/productPrototype/update',
    method: 'post',
    data
  })
}

export function read(data) {
  return request({
    url: `/api/prim_prod/prim_product/${data}/`,
    method: 'get',
    data
  })
}

export function edit(id, data) {
  return request({
    url: '/api/prim_prod/prim_product/' + id + '/',
    method: 'patch',
    data
  })
}

export function del(id) {
  return request({
    url: '/api/prim_prod/prim_product/' + id + '/',
    method: 'delete'
  })
}

export function bulkDel(data) {
  return request({
    url: '/api/image/image/bulk_delete/',
    method: 'delete',
    data
  })
}

export function createGripKnife(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/styleShowImageGroup/create',
    method: 'post',
    data
  })
}

export function changeGripKnife(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/styleShowImageGroup/update',
    method: 'post',
    data
  })
}

export function getByIdForUp(data) {
  return request({
    url: '/externaladmin/productService/productPrototype/getByIdForUp',
    method: 'post',
    data
  })
}

//获取原型列表
export function getPrototypeList(data, menuType = 'other') {
  return request({
    url: '/externaladmin/prototypeService/productPrototype/list',
    method: 'post',
    data
  })
}
