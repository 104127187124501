<template>
  <div class="app-container proto-list">
    <baseTable ref="table" :list="finalList" :data="finalData" checkAll>
      <template #menuLeft>
        <!-- 加入专题 -->
        <baseButton v-if="permission.addTopic" plain size="small" @click="addOrDelTopic('add')" @success="init"
        >加入专题</baseButton
        >
        <baseButton v-if="permission.batchTopic" plain size="small" @click="addOrDelTopic('del')" @success="init"
        >批量移除</baseButton
        >
      </template>

      <template #goodsStatus="{ row }">
        {{ getGoodsStatus(row) }}
      </template>

      <template #chineseName="{ row }">
        <div class="flex-center">
          <defaultImg :src="figure(row)" style="width: 60px; height: 60px"></defaultImg>
          <div>
            <div style="max-width: 200px">
              {{ row.chineseName || '暂无名字' }}
            </div>
          </div>
        </div>
      </template>

      <template #style="{ row }">
        <el-tag class="tag-medium" type="primary" :key="index" v-for="(item,index) in structs(row) || []">
          {{ item.name }}
        </el-tag>
      </template>

      <template #menu="{ row, $index }">
        <!-- 添加 -->
        <addOrDel
          v-if="permission.add && row.goodsStatus == 1"
          :rowIndex="$index"
          type="add"
          :data="row"
          @addHandler="addHandler(row)"
        />
        <!-- 移除 -->
        <addOrDel
          v-if="permission.remove && row.goodsStatus == 2"
          :rowIndex="$index"
          type="remove"
          :data="row"
          @delHandler="delHandler(row)"
        />
      </template>
    </baseTable>
    <div class="flex-middle-center">
      <el-button v-if="permission.back" @click="back">返回</el-button>
      <loading-btn v-if="permission.submit" type="primary" @click="doSubmit">保存</loading-btn>
    </div>
  </div>
</template>

<script>
import { list } from './const'
import baseTableMixin from '@/components/base/baseTable/mixins/baseTable'
import getBaseTableDataMixin from '@/components/base/baseTable/mixins/getBaseTableDataMixin'
import addOrDel from './module/addOrDel'
import recommendTopicsSitApi from '@/api/recommendTopicsSitApi'
import { map, cloneDeep } from 'lodash'
import { getPrototypeList } from '@/api/product/protoApi'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { getValueFromObj } from '@/utils'
export default {
  components: { addOrDel },
  name: 'addProduct',
  mixins: [
    baseTableMixin,
    getBaseTableDataMixin({
      dataAttrs: {
        curTabItem: {}
      }
    })
  ],
  data() {
    return {
      allData: [],
      topicData: []
    }
  },

  async mounted() {
    await awaitLoading(this.initData())
    this.dataHandler()
  },

  computed: {
    structs() {
      return (row) => {
        return row.styleList?.filter(({ name }) => {

          return name
        })
      }
    },
    figure() {
      return (row) => getValueFromObj(row, 'styleList[0].styleDisplayImageList[0].displayImagePath')
    },

    isTopic({ curTabItem }) {
      return curTabItem.value == 0
    },

    finalList() {
      return list.call(this)
    },

    marketingCategoryId({ $route }) {
      return $GET($route, 'query.id', null)
    },

    finalData({ curTabItem: { value } }) {
      return {
        0: this.topicData,
        1: this.allData
      }[value]
    },

    idList({ topicData }) {
      return map(topicData, 'id')
    }
  },

  methods: {
    getValueFromObj,
    async initData() {
      this.topicData =
        (await awaitResolveDetail(
          getPrototypeList({
            marketingCategoryId: this.$route.query.id
          })
        )) || []
      this.allData = (await awaitResolveDetail(getPrototypeList(REQUEST_ALL_DATA))) || []
    },

    dataHandler() {
      this.topicData.map((item) => this.$set(item, 'goodsStatus', 2))
      this.allData.map((item) => {
        if (this.idList.includes(item.id)) this.$set(item, 'goodsStatus', 2)
        else this.$set(item, 'goodsStatus', 1)
      })
    },

    // 批量加入/移除专题
    async addOrDelTopic(type) {
      const selectData = await this.getSelectionData()
      if (!selectData.length) return

      selectData.map((item) => (type == 'add' ? this.addHandler(item) : this.delHandler(item)))
    },

    // 移除
    delHandler(data) {
      if (this.isTopic) {
        const topicIndex = this.topicData.findIndex(item => item.id === data.id)
        if(topicIndex != -1) this.topicData.splice(topicIndex, 1)
      } else {
        data.goodsStatus = 1
        const topicIndex = this.topicData.findIndex(item => item.id === data.id)
        if(topicIndex != -1) this.topicData.splice(topicIndex, 1)
      }
    },

    // 添加
    addHandler(data) {
      if (this.idList.includes(data.id)) return
      this.topicData.push(data)
      data.goodsStatus = 2
    },

    // 保存
    async doSubmit() {
      if(!this.topicData.length) return this.$message.warning('请添加至少一个产品')
      const prototypeIdList = map(this.topicData, 'id')
      await awaitResolve(
        recommendTopicsSitApi.batchSet({
          prototypeIdList,
          categoryId: this.marketingCategoryId
        }),
        true,
        this.back(),
        this.init

      )
    },

    // 全部商品列表状态展示
    getGoodsStatus(data) {
      return {
        1: '',
        2: '已添加'
      }[data.goodsStatus]
    },

    back() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .app-container {
    display: flex;
    flex-direction: column;
  }
  .flex-one-page {
    height: 90%;
  }
}
.flex-middle-center {
  margin-top: 15px;
  width: 100%;
  height: 60px;
  bottom: 5px;
  border: 1px solid #ebebeb;
  flex: 0 0 60px;
}
</style>
