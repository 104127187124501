import { checkPermission, waitTimeByNum } from '@/utils'
import { getPrototypeList } from '@/api/product/protoApi'
import { map } from 'lodash'
import store from '@/store'
import { PRO_ADD, PRO_REMOVE } from '@/utils/constant'

export function list() {
  return {
    column: [
      {
        label: '专题商品',
        value: '0',
        permission: checkPermission('externaladmin:market:addProduct:topics'),
        getList: async () => {
          await waitTimeByNum(20) //延迟20ms，更新this.postData
          const detail = formatData.call(this, this.topicData)
          return {
            code: 0,
            detail,
            page: {
              total: detail.length
            }
          }

          function formatData(data) {
            let { styleNameLikeList = [] } = this.postData
            return filterData(data)

            function filterData(data) {
              if (!styleNameLikeList.length) return [...data]
              return data.filter(item => {
                const styleList = $GET(item, 'styleList', [])
                const fItem = styleList.find(sItem => {
                  const name = sItem.name
                  return styleNameLikeList.some(gItem => {
                    const reg = new RegExp(gItem, 'i')
                    return reg.test(name)
                  })
                })
                return fItem
              })
            }
          }
        },

        resetMergeData: {
          marketingCategoryId: this.$route.query.id
        },

        handleTableData: (data) => {
          return data
        },

        addHandler() {
          //空方法
        },

        delHandler(row) {
          const fIndexOfTopicData = this.topicData.findIndex(item => item.id === row.id)
          const fIndexOfTableData = this.tableData.findIndex(item => item.id === row.id)
          if (fIndexOfTopicData >= 0) {
            this.topicData.splice(fIndexOfTopicData, 1)
          }
          if (fIndexOfTableData >= 0) {
            this.tableData.splice(fIndexOfTopicData, 1)
          }
        },

        childPermission: {
          batchTopic: checkPermission('externaladmin:market:addProduct:topics:batchTopic'),
          // add: checkPermission('externaladmin:market:addProduct:topics:add'),
          remove: checkPermission('externaladmin:market:addProduct:topics:remove'),
          submit: checkPermission('externaladmin:market:addProduct:topics:submit'),
          back: checkPermission('externaladmin:market:addProduct:topics:back')
        },

        option: {
          border: true,
          page: false,
          resetBtn: false,
          isSearchAuto: false,
          column: [
            {
              label: '款式码',
              prop: 'styleNameLikeList',
              hide: true,
              search: true
            },
            {
              label: '原型',
              prop: 'chineseName'
            },
            {
              label: '款式',
              prop: 'style'
            }
          ]
        }
      },
      {
        label: '全部商品',
        value: '1',
        permission: checkPermission('externaladmin:market:addProduct:all'),
        getList: getPrototypeList,
        resetMergeData: {},

        handleTableData: (data) => {
          const idList = map(this.topicData, 'id')
          data.map(item => {
            if (idList.includes(item.id)) {
              this.$set(item, 'goodsStatus', PRO_REMOVE)
              return
            }
            this.$set(item, 'goodsStatus', PRO_ADD)
          })
          return data
        },

        delHandler(row) {
          row.goodsStatus = PRO_ADD
          const fIndexOfTopicData = this.topicData.findIndex(item => item.id === row.id)
          if (fIndexOfTopicData >= 0) {
            this.topicData.splice(fIndexOfTopicData, 1)
          }
        },

        addHandler(row) {
          row.goodsStatus = PRO_REMOVE
          this.topicData.push(row)
        },

        childPermission: {
          addTopic: checkPermission('externaladmin:market:addProduct:all:addTopic'),
          add: checkPermission('externaladmin:market:addProduct:all:add'),
          remove: checkPermission('externaladmin:market:addProduct:all:remove'),
          submit: checkPermission('externaladmin:market:addProduct:all:submit'),
          back: checkPermission('externaladmin:market:addProduct:all:back')
        },
        option: {
          border: true,
          resetBtn: false,
          isSearchAuto: false,
          column: [
            {
              label: '产品分类',
              prop: 'platformCategoryId',
              type: 'select',
              hide: true,
              search: true
            },
            {
              label: '款式',
              prop: 'styleNameLikeList',
              hide: true,
              search: true
            },

            {
              label: '原型',
              prop: 'chineseName'
            },
            {
              label: '款式',
              prop: 'style'
            },
            {
              label: '状态',
              prop: 'goodsStatus',
              dicData: store.getters.goodsStatus
            }
          ]
        }
      }
    ]
  }
}
