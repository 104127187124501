var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container proto-list"},[_c('baseTable',{ref:"table",attrs:{"list":_vm.finalList,"data":_vm.finalData,"checkAll":""},scopedSlots:_vm._u([{key:"menuLeft",fn:function(){return [(_vm.permission.addTopic)?_c('baseButton',{attrs:{"plain":"","size":"small"},on:{"click":function($event){return _vm.addOrDelTopic('add')},"success":_vm.init}},[_vm._v("加入专题")]):_vm._e(),(_vm.permission.batchTopic)?_c('baseButton',{attrs:{"plain":"","size":"small"},on:{"click":function($event){return _vm.addOrDelTopic('del')},"success":_vm.init}},[_vm._v("批量移除")]):_vm._e()]},proxy:true},{key:"goodsStatus",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getGoodsStatus(row))+" ")]}},{key:"chineseName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-center"},[_c('defaultImg',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":_vm.figure(row)}}),_c('div',[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(row.chineseName || '暂无名字')+" ")])])],1)]}},{key:"style",fn:function(ref){
var row = ref.row;
return _vm._l((_vm.structs(row) || []),function(item,index){return _c('el-tag',{key:index,staticClass:"tag-medium",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(item.name)+" ")])})}},{key:"menu",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(_vm.permission.add && row.goodsStatus == 1)?_c('addOrDel',{attrs:{"rowIndex":$index,"type":"add","data":row},on:{"addHandler":function($event){return _vm.addHandler(row)}}}):_vm._e(),(_vm.permission.remove && row.goodsStatus == 2)?_c('addOrDel',{attrs:{"rowIndex":$index,"type":"remove","data":row},on:{"delHandler":function($event){return _vm.delHandler(row)}}}):_vm._e()]}}])}),_c('div',{staticClass:"flex-middle-center"},[(_vm.permission.back)?_c('el-button',{on:{"click":_vm.back}},[_vm._v("返回")]):_vm._e(),(_vm.permission.submit)?_c('loading-btn',{attrs:{"type":"primary"},on:{"click":_vm.doSubmit}},[_vm._v("保存")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }