<template>
  <Popover @sureHandler="addOrDelHandler($event,type)">
    <template #tip>
      <p>确定要{{ btnTitle.content }}此产品吗？</p>
    </template>
    <template #reference="{ scope: loading }">
      <ColorTextBtn class="delete-btn"  :type="btnTitle.type" :loading="loading">{{ btnTitle.text }}</ColorTextBtn>
    </template>
  </Popover>
</template>

<script>


export default {
  props: {
    rowIndex: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      default: 'add'
    },
  },
  computed: {
    btnTitle({ type }) {
      return {
        add: {
          content: '添加',
          text: '添加',
          type: 'text'
        },
        remove: {
          content: '移除',
          text: '移除',
          type: 'info'
        },
      }[type]
    }
  },
  methods: {
    addOrDelHandler($el,type) {
      if(type=='add'){
         this.$emit('addHandler', this.rowIndex, $el, this)
      }else{
        this.$emit('delHandler', this.rowIndex, $el, this)
      }
     
      const doClose = $GET( $el, '$refs.popover.doClose', false)
      doClose && doClose()
    }
  }
}
</script>

